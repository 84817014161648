import React from "react"
import Header from "aws-northstar/components/Header";

const PageHeader = () => {
  return(
    <div>
      <Header title="Translation Evaluator"/>
    </div>
  );
}

export default PageHeader;
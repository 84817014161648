import React from "react";
import { withStyles } from "@material-ui/core/styles";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import Heading from "aws-northstar/components/Heading";

const styles = (theme) => ({
  root: {
      position: 'fixed',
      top: theme.spacing(6),
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
  },
  container: {
      width: 600,
      height: 300,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
  },
  icon: {
      fontSize: 160,
      color: 'rgba(0,0,0,0.25)',
  },
  text: {
      color: 'rgba(0,0,0,0.5)',
  },
});

const EmptyView = (props) => {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <SaveAltIcon className={classes.icon} />
        <Heading className={classes.text} variant="h3">
          Drag &amp; drop your translation diff file here
        </Heading>
      </div>
    </div>
  )
};

export default withStyles(styles)(EmptyView);
import React from "react";
import FileDropzone from "../../components/FileDropzone";
import DiffView from "../../components/DiffView";

const PageContainer = () => {
  const [diffs, setDiffs] = React.useState([]);
  
  return(
    <div>
      { diffs.length == 0 ? <FileDropzone setDiffs={setDiffs}/> : <DiffView diffs={diffs}/>}
    </div>
  );
};

export default PageContainer;

import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";

import EmptyView from "../../components/EmptyView";

const FileDropzone = ({setDiffs}) => {

  const onDrop = useCallback((files) => {
    const allowedTypes = [
      "text/plain",
      "application/json"
    ];
    
    if (!allowedTypes.includes(files[0].type)) {
      alert(`Error in processing ${files[0].name}: ${files[0].type} is not a supported file type.`);
      return;
    }
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        console.log(JSON.parse(e.target.result))
        setDiffs(JSON.parse(e.target.result));
      } catch (error) {
        alert(`Failed to load the file ${files[0].name}: ${error}`);
      }
    }
    reader.readAsText(files[0]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return(
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <EmptyView />
    </div>
  );
};

export default FileDropzone;
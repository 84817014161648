import PageHeader from "./components/PageHeader";
import PageContainer from "./components/PageContainer";

function App() {
  return (
    <div>
      <PageHeader />
      <PageContainer />
    </div>
  );
}

export default App;

import React, { useCallback } from "react";
import Container from 'aws-northstar/layouts/Container';
import ExpandableSection from 'aws-northstar/components/ExpandableSection';
import Grid from 'aws-northstar/layouts/Grid';
import Stack from 'aws-northstar/layouts/Stack';
import Text from 'aws-northstar/components/Text';

import DiffMatchPatch from 'diff-match-patch'
import parse from 'html-react-parser';

const DiffView = ({diffs}) => {
  return(
    <div>
      { diffs.map((diff) => (<DiffContent diff={diff}/>)) }
    </div>
  )
};

const DiffContent = ({diff}) => {
  return(
    <div>
      <Container 
        headingVariant='h4'
        title={"https://issues.amazon.com/issues/" + diff["IssueId"]}
      >
        <TranslationDiff translationDiff={diff}/>
      </Container> 
    </div>
  )
}

const escapeHtml = (content) => {
  if(typeof content !== 'string') {
    return content;
  }
  return content.replace(/[&'`"<>]/g, (match) => {
    return {
      '&': '&amp;',
      "'": '&#x27;',
      '`': '&#x60;',
      '"': '&quot;',
      '<': '&lt;',
      '>': '&gt;',
    }[match]
  });
}

const TranslationDiff = ({translationDiff}) => {
  const dmp = new DiffMatchPatch();
  const realtimeTitle = escapeHtml(translationDiff["Realtime_Title"]);
  const bedrockTitle = escapeHtml(translationDiff["Bedrock_Title"]);
  const realtimeEmail = escapeHtml(translationDiff["Realtime_Email"]);
  const bedrockEmail = escapeHtml(translationDiff["Bedrock_Email"]);
  const realtimePhd = escapeHtml(translationDiff["Realtime_PHD"]);
  const bedrockPhd = escapeHtml(translationDiff["Bedrock_PHD"]);

  const titleDiffs = dmp.diff_main(realtimeTitle, bedrockTitle);
  const emailDiffs = dmp.diff_main(realtimeEmail, bedrockEmail);
  const phdDiffs = dmp.diff_main(realtimePhd, bedrockPhd);

  const diffTypes = [
    {
        "Type": "Title",
        "Diffs": titleDiffs,
    },
    {
        "Type": "Email",
        "Diffs": emailDiffs,
    },
    {
        "Type": "PHD",
        "Diffs": phdDiffs,
    },
  ];
  let translationTexts = {};
  
  diffTypes.forEach((diffType) => {
    let realtime = "";
    let bedrock = "";
    let count = 1;

    diffType["Diffs"].forEach((diff) => {
      if (diff[0] === 0) {
        realtime += diff[1]
        bedrock += diff[1]
      }
      if (diff[0] === -1) {
        realtime += `<span style='background: #A0D7E2'>${diff[1]}</span>`
        count += 1;
      }
      if (diff[0] === 1) {
        bedrock += `<span style='background: #ecb4b4'>${diff[1]}</span>`
        count += 1;
      }
    });

    let scores = ""
    console.log(translationDiff[diffType.Type + "_Score"])
    translationDiff[diffType.Type + "_Score"].forEach((score) => {
      scores += String(Math.round(parseFloat(score) * 10000) / 10000) + " / ";
    });

    translationTexts[diffType.Type] = {
      "Realtime": realtime.replace(/\n|\r/g, "<br/>"),
      "Bedrock": bedrock.replace(/\n|\r/g, "<br/>"),
      "Score": scores.slice(0, -2)
    };
  });

  return(
    <ExpandableSection expanded={true} variant="container">
      <Stack>
        <Stack spacing="xs">
          <h2>Title - Scores (P/R/F1) ---- {translationTexts.Title.Score}</h2>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Container headingVariant="h6" title="Realtime Translation">
                <Text>
                  {parse(translationTexts.Title.Realtime)}
                </Text>
              </Container>
            </Grid>
            <Grid item xs={6}>
              <Container headingVariant="h6" title="Bedrock Translation">
                <Text>
                  {parse(translationTexts.Title.Bedrock)}
                </Text>
              </Container>
            </Grid>
          </Grid>
        </Stack>
        <Stack spacing="xs">
          <h2>Email - Scores (P/R/F1) ---- {translationTexts.Email.Score}</h2>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Container headingVariant="h6" title="Realtime Translation">
                <Text>
                  {parse(translationTexts.Email.Realtime)}
                </Text>
              </Container>
            </Grid>
            <Grid item xs={6}>
              <Container headingVariant="h6" title="Bedrock Translation">
                <Text>
                  {parse(translationTexts.Email.Bedrock)}
                </Text>
              </Container>
            </Grid>
          </Grid>
        </Stack>
        <Stack spacing="xs">
          <h2>PHD - Scores (P/R/F1) ---- {translationTexts.PHD.Score}</h2>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Container headingVariant="h6" title="Realtime Translation">
                <Text>
                  {parse(translationTexts.PHD.Realtime)}
                </Text>
              </Container>
            </Grid>
            <Grid item xs={6}>
              <Container headingVariant="h6" title="Bedrock Translation">
                <Text>
                  {parse(translationTexts.PHD.Bedrock)}
                </Text>
              </Container>
            </Grid>
          </Grid>
        </Stack>
      </Stack>
    </ExpandableSection>
  )
}

export default DiffView;